@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  background: #f8f8f8;
}

.single-slide {
  min-height: 75vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}

footer {
  background: #2b2b2b;
  padding: 15px 0;
  padding-bottom: 20px;
  padding-top: 20px;
}

.footer-copyright p {
  margin-bottom: 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.footer-social {
  margin: 25px 0;
}

.footer-social ul {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-social ul li a {
  display: block;
  background: #ffffff;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid #7070706e;
  margin: 0 5px;
  transition: 0.3s;
}
.footer-social ul li a:hover {
  box-shadow: 0px 3px 6px #00000030;
}
.footer-social ul li a svg {
  transition: 0.3s;
}
.footer-social ul li a:hover svg {
  color: #5a36da !important;
}

.loader-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
}

.product-title {
  position: relative;
  font-size: 45px;
  display: inline-block;
  font-weight: 700;
}
.product-title:before {
  position: absolute;
  content: "";
  height: 7px;
  background: #ffdd00;
  width: 100%;
  bottom: 10px;
  z-index: -1;
}

img {
  max-width: 100%;
}

.logo img {
  width: 135px;
  padding: 20px 0;
}

.product-wrapper {
  padding-bottom: 50px;
}

.cat-head {
  padding: 45px 0;
}

.cat-head h3 {
  font-weight: bold;
  margin-bottom: 20px;
}
.cat-head p {
  color: #0c0c0cb8;
  text-align: justify;
}

.product-single {
  margin-bottom: 30px;
}

.product-single h4 {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 28px;
  margin-top: 10px;
}

.product-single img {
  width: auto;
  border-radius: 6px;
  height: 200px;
}

.product-single a {
  display: block;
  text-align: center;
  border-radius: 4px;
  padding: 10px 0;
  color: #000;
  text-decoration: none;
  letter-spacing: 1px;
  transition: 0.3s;
  background: #fff;
  overflow: hidden;
  border-radius: 8px;
}
.product-single a:hover {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.16);
}

.pad-50 {
  padding: 80px 0;
}

.product-list {
  color: #000;
  text-decoration: none;
  margin: 10px 10px;
  display: block;
  background: #fff;
  height: 100%;
  border-radius: 8px !important;
  overflow: hidden;
  transition: 0.3s;
}
.product-list:hover {
  color: #000;
  text-decoration: none;
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.16);
}

.contact-header h3 {
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
}
.contact-header h3:after {
  position: absolute;
  content: "";
  background: #ffdd00;
  width: 100%;
  height: 5px;
  left: 0;
  bottom: -5px;
}
.recog-header {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
}
.recog-header:after {
  position: absolute;
  content: "";
  background: #ffdd00;
  width: 100%;
  height: 5px;
  left: 0;
  bottom: -5px;
}

.contact-info {
  padding: 20px 0;
  margin-bottom: 35px;
}

.contact-info ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contact-info ul li {
  padding-bottom: 35px;
  display: flex;
  font-weight: 400;
  align-items: center;
  font-size: 18px;
}
.contact-info ul li:last-child {
  padding-bottom: 0;
}
.contact-info ul li svg {
  width: auto;
  height: 35px;
  margin-right: 30px;
}

.contact-form input,
.contact-form textarea {
  border: 2px solid #ffcc00;
  background: transparent;
}
.contact-form input {
  height: 50px;
}
.contact-form input:focus,
.contact-form textarea:focus {
  border: 2px solid #ffcc00;
  background: transparent;
  box-shadow: none;
}

.contact-page {
  margin-bottom: 80px;
}

.submit-btn {
  background: #ffcc00;
  color: #000;
  border: 0px;
  border-radius: 2px;
  font-weight: 500;
  width: 100%;
  padding: 10px;
  transition: 0.3s;
}
.submit-btn:hover {
  background: #d0b134;
}

.about-head h2 {
  font-size: 40px;
  margin-bottom: 10px;
}
.about-head h4 {
  color: #ffcc00;
  font-size: 20px;
  margin-bottom: 25px;
}

.about-head p {
  margin-bottom: 40px;
}

.cta-btn {
  padding: 15px 40px;
}

.cta-btn:hover {
  color: #000;
  text-decoration: none;
}

.evolve-area h2 {
  margin-bottom: 40px;
}
.evolve-area p {
  text-align: justify;
}

.fea-area h3 {
  margin-bottom: 30px;
}

.ego-label {
  padding: 7px 0px !important;
  border-radius: 3px !important;
  padding-right: 15px !important;
}
.mob-menu {
  display: none;
  position: relative;
  min-height: 30px;
}
.product_image {
  width: 100%;
}
.mob-trigger {
  z-index: 88;
  position: absolute;
  right: 0;
  width: 100%;
}
.mob-trigger button {
  background: transparent;
  border: 0px;
  outline: none;
}
.mob-trigger button img {
  width: 30px;
}

.mob-menu-content.closed {
  transition: 0.5s ease;
  transform: translateY(-100%);
  display: none;
}
.mob-menu-content.open {
  transition: 0.5s ease;
  transform: translateY(0%);
  margin-top: 35px;
}

.mob-menu-content ul li {
  display: block;
  text-align: left;
}
.mob-menu-content ul li a {
  margin-bottom: 7px;
}
.mob-menu {
  overflow: hidden;
}

.page-title {
  text-align: center;
  width: 100%;
  position: relative;
}

.page-title h1 {
  position: relative;
  font-size: 55px;
  display: inline-block;
  font-weight: 700;
}
.page-title h1:before {
  position: absolute;
  content: "";
  height: 7px;
  background: #ffdd00;
  width: 100%;
  bottom: 15px;
  z-index: -1;
}
.cate-title {
  width: 100%;
  position: relative;
}

.cate-title h1 {
  position: relative;
  font-size: 45px;
  display: inline-block;
  font-weight: 700;
}
.cate-title h1:before {
  position: absolute;
  content: "";
  height: 7px;
  background: #ffdd00;
  width: 100%;
  bottom: 10px;
  z-index: -1;
}

.nav-link {
  color: #000 !important;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  padding: 0 15px !important;
  text-decoration: none;
}

.dropdown-menu {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.16);
  border: 0;
}
.dropdown-item {
  padding: 10px 15px !important;
  font-weight: 500;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #ffdd00;
  color: #000;
}

.product-desc p {
  color: rgba(108, 108, 108, 1);
  font-size: 18px;
}

.product-desc ul {
  list-style: none;
}

.product-desc li {
  position: relative;
  margin-bottom: 15px;
  font-size: 18px;
}
.product-desc li:before {
  position: absolute;
  content: "";
  width: 30px;
  height: 5px;
  background: rgba(255, 221, 0, 1);
  left: -40px;
  top: 10px;
}
.single-slide {
  padding: 20px 0;
}
.single-slide h1 {
  font-size: 65px;
  margin-bottom: 40px;
}
.single-slide p {
  color: #666;
  font-size: 22px;
  margin-bottom: 80px;
}
.single-slide a {
  text-align: center;
  background: #ffdd00;
  margin-top: 15px;
  border-radius: 4px;
  padding: 20px 45px;
  color: #000;
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
  letter-spacing: 1px;
  transition: 0.3s;
}

.custom-modal .modal-content {
  background: transparent;
  border: 0;
}

.custom-modal .modal-header {
  background: transparent;
  border: 0;
}
.custom-modal .modal-header button {
  background: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 20px;
  font-size: 32px;
  top: 35px;
  position: absolute;
  right: 0;
  z-index: 5;
  opacity: 1;
}

@media only screen and (max-width: 991px) {
  .navbar-collapse {
    position: fixed;
    left: 0px;
    top: 105px;
    background: rgb(255, 255, 255);
    width: 100%;
    z-index: 5;
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
  .nav-link {
    margin-bottom: 20px;
  }
  header {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 5;
    border-bottom: 1px solid #ddd;
  }
  .navbar {
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 600px) {
  .single-slide {
    padding: 0 25px;
  }
  .single-slide img {
    max-width: 80%;
    margin-top: 30px;
  }
  .single-slide h1 {
    font-size: 35px;
    margin-top: 80px;
  }
  .single-slide p {
    font-size: 16px;
  }
  .single-slide a {
    margin-bottom: 60px;
  }
  .swiper-wrapper {
    min-height: 750px;
  }
  .single-slide {
    min-height: 170px;
  }
  .desk-menu {
    display: none;
  }
  .mob-menu {
    display: block;
    width: 100%;
  }
  .mob-trigger {
    text-align: right;
  }
  .ego-label {
    width: 135px;
    text-align: center;
  }
}
